import React, { Component } from "react";
import withStyles, { WithStylesProps } from 'react-jss';

const styles = {
    statusInfo: {
        display: 'flex',
        boxSizing: 'border-box',
        position: 'absolute',
        justifyContent: 'space-between',
        top: 0,
        width: '100%',
        padding: '10px',
        '& ul': {
            listStyleType: 'none',
            margin: 0,
            padding: 0
        }
    },
    rightAlign: {
        textAlign: 'right'
    }
};

interface IServerStatusProps extends WithStylesProps<typeof styles> {};
interface IServerStatusState {
    status: any
}
class ServerStatus extends Component<IServerStatusProps, IServerStatusState> {
    constructor(props: IServerStatusProps) {
        super(props);
        this.state = {
            status: {}
        };
    }
    async getStatus() {
        const response = await fetch('https://api.mcsrvstat.us/2/jelly.rocketnotfound.com');
        const results = await response.json();
        console.log(results);
        return results;
    }
    componentDidMount() {
        this.getStatus().then((json: any) => {
            this.setState({ status: json });
        });
    }
    render() {
        const { classes } = this.props;
        const { status } = this.state;
        return (
            <div className={classes.statusInfo}>
                {
                    status.online ? 
                    (
                        <>
                        <div>
                            <span>Online</span>
                        </div>
                        <div className={classes.rightAlign}>
                            <span>{status.players.online}/{status.players.max}</span>
                            {
                                status.players.list ?
                                (
                                    <>
                                    <br />
                                    <ul>
                                    {
                                        status.players.list.map((player: string) => <li><em>{player}</em></li>)
                                    }
                                    </ul>
                                    </>
                                ) : null
                            }
                        </div>
                        </>
                    ) :
                    (<div><span>Offline</span></div>)
                }
            </div>
        );
    }
}

export default withStyles(styles)(ServerStatus);
