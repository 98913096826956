import React, { Component } from "react";
import Display from './3d-model-display/Display';
import ServerStatus from './mc-status/ServerStatus';
import withStyles, { WithStylesProps } from 'react-jss';

const styles = {
    '@font-face': [
        {
            fontFamily: 'eight-bit-operator',
            src: 'url(/fonts/8bitOperatorPlus-Regular.ttf)'
        },
        {
            fontFamily: 'eight-bit-operator',
            src: 'url(/fonts/8bitOperatorPlus-Bold.ttf)',
            fontWeight: 'bold'
        }
    ],
    parent: {
        width: '100vw',
        height: '100vh',
        fontFamily: 'eight-bit-operator'
    }
};

interface IAppProps extends WithStylesProps<any> {};
class App extends Component<IAppProps> {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.parent}>
                <Display />
                <ServerStatus />
            </div>
        );
    }
}

export default withStyles(styles as any)(App);
