import React, { Component } from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import withStyles, { WithStylesProps } from 'react-jss';

const styles = {
    fullSized: {
        width: '100%',
        height: '100%'
    }
};

interface IDisplayProps extends WithStylesProps<typeof styles> {}
class Display extends Component<IDisplayProps> {
    private mount: any;

    componentDidMount() {
        const RAD = Math.PI / 180;

        const scene = new THREE.Scene();

        // #region Renderer Setup
        const renderer = new THREE.WebGLRenderer({ canvas: this.mount, alpha: true });
        renderer.shadowMap.enabled = true;
        renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        renderer.outputEncoding = THREE.sRGBEncoding;
        // #endregion

        // #region Floor Grid
        const helper = new THREE.GridHelper( 2000, 100 );
        helper.position.y = 0;
        scene.add( helper );
        // #endregion

        // #region Plane
        const planeGeometry = new THREE.PlaneBufferGeometry( 2000, 2000 );
        planeGeometry.rotateX( -90 * RAD );

        const planeMaterial = new THREE.ShadowMaterial({ opacity: .2 });

        const plane = new THREE.Mesh( planeGeometry, planeMaterial );
        plane.position.y = -.5;
        plane.receiveShadow = true;
        scene.add( plane );
        // #endregion

        // #region Camera
        const camera = new THREE.PerspectiveCamera(75, 1, 1, 1000);
        camera.position.set(0, 15, 50);
        camera.rotation.set(0, 0, 0);
        // #endregion

        // #region Lighting
        const ambientLight = new THREE.AmbientLight(0x707070);
        scene.add(ambientLight);

        const light = new THREE.SpotLight(0xffffff, 1);
        light.position.set(0, 200, 200);
        light.angle = Math.PI * 0.2;
        light.castShadow = true;
        light.shadow.mapSize.width = 1080;
        light.shadow.mapSize.height = 1080;
        scene.add( light );
        scene.add( light.target );
        // #endregion

        // Distance between the light and camera
        const lightCamVecDist = light.position.distanceTo(camera.position);

        // #region  Camera Controls
        const controls = new OrbitControls(camera, renderer.domElement);
        controls.target.set(0, 15, 0);
        controls.enablePan = false;
        controls.enableDamping = true;
        controls.maxDistance = 50;
        controls.update();
        // #endregion

        // #region GLTF Model Setup
        // Animation Variables
        let mixer: THREE.AnimationMixer;
        let wmillAnim: THREE.AnimationAction;
        let hrAnim: THREE.AnimationAction;
        let minAnim: THREE.AnimationAction;

        // Animation timers used for controlling when to contine animation
        let hrAnimTimer = 0;
        let minAnimTimer = 0;

        // Variables indicating time between keyframes
        // This assumes that the animation was timed with keyframes equally
        // apart
        let hrAnimStepTime = 0;
        let minAnimStepTime = 0;

        // Variables indicating how many keyframes were used
        const hrAnimKeyFrames = 12;
        const minAnimKeyFrames = 12;

        // Load our model
        const loader = new GLTFLoader();
        loader.load('./3d-models/ClockTowerArch.glb', function (gltf) {
            scene.add( gltf.scene );

            gltf.scene.traverse( function( child ) { 
                child.castShadow = true;
                child.receiveShadow = true;
            });

            mixer = new THREE.AnimationMixer(gltf.scene);
            wmillAnim = mixer.clipAction(gltf.animations[0]);
            hrAnim = mixer.clipAction(gltf.animations[1]);
            minAnim = mixer.clipAction(gltf.animations[2]);

            // Get current Date/Time
            const now = new Date();

            // We want 12 hour time for the hours
            const tmpHour = (now.getHours() > 12) ? now.getHours() - 12 : now.getHours();
            // Currently the animation has 12 keyframes and we want any value from 0 - 12
            // times the hrAnimStepTime to indicate what value not to exceed for the
            // animation time
            hrAnimStepTime = hrAnim.getClip().duration / hrAnimKeyFrames;
            // We make the hrAnim time to be the current hour but have the animation be
            // slighty before so that unnecessary animations don't play
            hrAnim.time = (tmpHour * hrAnimStepTime) - 1;
            hrAnim.paused = true;
            // Setup the hour timer by hour and offset by the current seconds
            hrAnimTimer = tmpHour * 3600 + now.getSeconds();

            // Similar to the above block but dealing with the minutes animation
            const tmpMin = now.getMinutes();
            minAnimStepTime = minAnim.getClip().duration / minAnimKeyFrames;
            minAnim.time = (Math.floor(tmpMin / 5) * minAnimStepTime) - 1;
            minAnim.paused = true;
            minAnimTimer = tmpMin * 60 + now.getSeconds();

            // Play our desired animations
            wmillAnim.play();
            hrAnim.play();
            minAnim.play();

            // Listener for when an animation loop finishes
            mixer.addEventListener('loop', (e) => {
                // If either the hr animation or the min animation finishes looping
                // set their respective timers to 1 second since the animation loop
                // used does a rotation starting from 0 -> 360 in 10 seconds. Which
                // means at the hrAnimTime = 10 it should be the same as hrAnimTime
                // = 0. But since we don't want the a delay between 10 and 0 we
                // start the new loop at 1.
                if(e.action === hrAnim) {
                    hrAnimTimer = 1;
                }
                if(e.action === minAnim) {
                    minAnimTimer = 1;
                }
            });

            // Once our setup is complete lets start our main loop
            requestAnimationFrame(animate);
        }, undefined, function (error) {
            console.error(error);
        });
        // #endregion

        // #region Resizing Logic
        const resizeCanvasToDisplaySize = function () {
            const canvas = renderer.domElement;
            const width = canvas.clientWidth;
            const height = canvas.clientHeight;

            if (canvas.width !== width || canvas.height !== height) {
                // you must pass false here or three.js sadly fights the browser
                renderer.setSize(width, height, false);
                camera.aspect = width / height;
                camera.updateProjectionMatrix();
            }
        }
        // #endregion

        // #region Interactables
        let baseSpeed = 0;
        let currSpeed = 0;
        let prevSpeed = 0;

        const changeWindmillSpeed = () => {
            if (wmillAnim !== null) {
                if (baseSpeed === 0) baseSpeed = wmillAnim.timeScale;
                wmillAnim.paused = false;

                prevSpeed = wmillAnim.timeScale;
                if(++currSpeed > 5) currSpeed = 0;
                wmillAnim.warp(prevSpeed, baseSpeed * Math.pow(2, currSpeed), 1);
            }
        }

        this.mount.addEventListener('click', changeWindmillSpeed, false);
        this.mount.addEventListener('touchend', changeWindmillSpeed, false);
        // #endregion

        // #region Main Loop
        let autoRotatedOnce = false;
        const clock = new THREE.Clock();
        const animate = function () {
            resizeCanvasToDisplaySize();

            const delta = clock.getDelta();
            hrAnimTimer += delta;
            minAnimTimer += delta;

            if (!autoRotatedOnce && controls.autoRotate && Math.floor(controls.getAzimuthalAngle()) >= 0) {
                autoRotatedOnce = true;
                controls.autoRotate = false;
                controls.enableDamping = true;
            }
            if(!autoRotatedOnce && !controls.autoRotate && clock.getElapsedTime() > 5) {
                controls.autoRotate = true;
                controls.enableDamping = false;
            }

            // 3600 = 60 seconds * 60 minutes
            // 300 = 60 seconds * 5 minutes
            hrAnim.paused = (hrAnim.time > Math.floor(hrAnimTimer / 3600) * hrAnimStepTime);
            minAnim.paused = (minAnim.time > Math.floor(minAnimTimer / 300) * minAnimStepTime);

            const tmpLightVec = new THREE.Vector3();
            tmpLightVec.copy(camera.position).normalize().multiplyScalar(lightCamVecDist);
            light.position.copy(tmpLightVec);

            mixer.update(delta);
            controls.update();

            renderer.render(scene, camera);
            requestAnimationFrame(animate);
        };
        // #endregion
    }

    render() {
        const { classes }= this.props;
        return (
            <canvas className={classes.fullSized} ref={ref => (this.mount = ref)} />
        )
    }
}

export default withStyles(styles)(Display);